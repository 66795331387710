import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assets/css/bootstrap.min.css";
 import "./assets/css/app.min.css"
import "./assets/css/icons.min.css";
import "./assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "./assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "./assets/libs/datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import { Provider } from 'react-redux';
import { Store } from './Redux/Store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
